import { useRecoilState } from "recoil";
import Lookup from "../Lookup";
import { loadLookupState, mainViewState } from "../../atoms/AppAtome";
import { useEffect, useState } from "react";

import { App, Button } from "antd";
import axios from "axios";
import { APP_URL } from "../../URL";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import Home from "./Home";
import SchoolList from "./SchoolList";
import CreateMySchool from "./CreateMySchool";
import Start from "./rapport_rentrees/Start";
import Intro from "./rapport_rentrees/enseignement_bases/Intro";
import Enseignement from "./rapport_rentrees/enseignement_bases/Etape1_enseignement";
import Trans1 from "./rapport_rentrees/enseignement_bases/Trans1";
import Infrastrucure from "./rapport_rentrees/enseignement_bases/Etape2_infrastructure";
import Trans2 from "./rapport_rentrees/enseignement_bases/Trans2";
import Administration from "./rapport_rentrees/enseignement_bases/Etape3_administration";
import Deroulement_rentree from "./rapport_rentrees/enseignement_bases/Etape4_deroulement_rentree";
import Repartition_effectifs from "./rapport_rentrees/enseignement_bases/Etape6_repartition_effectifs";
import Synthese_effectifs from "./rapport_rentrees/enseignement_bases/Etape7_synthese_effectif";
import Repartition_effectifs_trois_annees from "./rapport_rentrees/enseignement_bases/Etape8_repartition_effectif_trois_annee";
import LookSomeSchool from "./rapport_rentrees/enseignement_bases/Look_somme_school";
import RapportsRentreeList from "./rapport_rentrees/Rapports_rentree_list";
import MyEnseigmentBase from "./rapport_rentrees/enseignement_bases/MyEnseigmentBase";
import DetailUpdateRapport from "./rapport_rentrees/enseignement_bases/DetailUpdateRapport";
import InfrastructureUpdate from "./rapport_rentrees/enseignement_bases/updates/InfrastructureUpdate";


export default function Dashboad(){

    const [loadingLookup, setLoadingLookup] = useRecoilState(loadLookupState);
    const [mainView, setMainView] = useRecoilState(mainViewState);
    const [loading, setLoading] = useState(false);
    const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);
    const [haveSchool, setHaveSchool] = useState(true);
    const navigate = useNavigate();

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${cookies.seduc_token}`
      };
    
    const config = {
      headers: headers,
      };

    const logouter = () => {
        setLoading(true);

        axios.get(APP_URL + 'logout',config).then((response) => {
            setLoading(false);
            // Supprimer le cookie 'seduc_token'
            removeCookie('seduc_token');

            // navigate('/login');
            window.location.href = '/login';

        }).catch((error) => {
          console.log(error)
        })

    }
    
    useEffect(() => {

        setLoadingLookup(false);
        axios.get(APP_URL+'user/look-have-school',config).then((response) => {
            setHaveSchool(response.data.bool);
            console.log(response.data);
        }).catch((error) => {
            console.log(error);
        })

        const handleBeforeUnload = (event) => {
          const message = " En Actualisant la page, vous serez renvoyer en page d'accueil. voulez-vous actualiser ?";
          event.returnValue = message; // Standard pour la plupart des navigateurs
          return message; // Pour Internet Explorer
        };
    
        window.addEventListener('beforeunload', handleBeforeUnload);
    
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };

    },[]);

   
    return (
        <>
    <Lookup />
    <nav class="navbar navbar-expand-lg navbar-dark bg-default fixed-top" id="mainNav">
    <a class="navbar-brand" href="javascript:void(0)">
        {/* <img src="img/logo.png" data-retina="true" alt="" width="165" height="36"/> */}
        <h3 style={{background:'white', width:'180px', padding:'2%'}}> <b style={{color:'blue'}}>SEDUC APP</b></h3>
    </a>
    <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarResponsive">
      <ul class="navbar-nav navbar-sidenav" id="exampleAccordion">
        <li class="nav-item" data-toggle="tooltip" data-placement="right" title="Dashboard">
          <a class="nav-link" href="javascript:void(0)" onClick={() => setMainView('dashboad')}>
            <i class="fa fa-fw fa-dashboard"></i> &nbsp;
            <span class="nav-link-text">Dashboard</span>
          </a>
        </li>

        {haveSchool?<>

          <li class="nav-item" data-toggle="tooltip" data-placement="right" title="Messages">
          <a class="nav-link" href="javascript:void(0)" onClick={() => setMainView('addSchool')}>
            <i class="fa fa-fw fa-home"></i> &nbsp;
            <span class="nav-link-text">Mes ecoles</span>
          </a>
          </li>

        </>:<>

        <li class="nav-item" data-toggle="tooltip" data-placement="right" title="Messages">
          <a class="nav-link" href="javascript:void(0)" onClick={() => setMainView('createMySchool')}>
            <i class="fa fa-fw fa-home"></i> &nbsp;
            <span class="nav-link-text">Creer votre ecole</span>
          </a>
        </li>

        </>}

          <hr />

        <li class="nav-item" data-toggle="tooltip" data-placement="right" title="Enregistre un nouveau rapport de rentree">
          <a class="nav-link" href="javascript:void(0)" onClick={() => setMainView('look_some_school')}>
            <i class="fa fa-fw fa-book"></i> &nbsp;
            <span class="nav-link-text">Rapports de rentrées <span class="badge badge-pill badge-primary">New</span></span>
          </a>
        </li>
        <li class="nav-item" data-toggle="tooltip" data-placement="right" title="Enregistre un nouveau rapport de fin d'annee">
          <a class="nav-link" href="javascript:void(0)">
            <i class="fa fa-fw fa-book"></i> &nbsp;
            <span class="nav-link-text">Rapports de fin d'année <span class="badge badge-pill badge-primary">New</span></span>
          </a>
        </li>

        <hr />

        <li class="nav-item" data-toggle="tooltip" data-placement="right" title="Liste de vos rapports de rentrees">
          <a class="nav-link" href="javascript:void(0)" onClick={() => setMainView('rapports_rentree_list')}>
            <i class="fa fa-fw fa-book"></i> &nbsp;
            <span class="nav-link-text">Rapports de rentrées <span class="badge badge-pill badge-success">List</span></span>
          </a>
        </li>

        <li class="nav-item" data-toggle="tooltip" data-placement="right" title="Liste de vos rapport de fin d'annee">
          <a class="nav-link" href="javascript:void(0)">
            <i class="fa fa-fw fa-book"></i> &nbsp;
            <span class="nav-link-text">Rapports de fin d'année <span class="badge badge-pill badge-success">List</span></span>
          </a>
        </li>
		
		{/* <li class="nav-item" data-toggle="tooltip" data-placement="right" title="My listings">
          <a class="nav-link nav-link-collapse collapsed" data-toggle="collapse" href="#collapseMylistings" data-parent="#mylistings">
            <i class="fa fa-fw fa-list"></i>
            <span class="nav-link-text">My listings</span>
          </a>
          <ul class="sidenav-second-level collapse" id="collapseMylistings">
            <li>
              <a href="listings.html">Pending <span class="badge badge-pill badge-primary">6</span></a>
            </li>
			<li>
              <a href="listings.html">Active <span class="badge badge-pill badge-success">6</span></a>
            </li>
			<li>
              <a href="listings.html">Expired <span class="badge badge-pill badge-danger">6</span></a>
            </li>
          </ul>
        </li> */}
		
		

      </ul>
      <ul class="navbar-nav sidenav-toggler">
        <li class="nav-item">
          <a class="nav-link text-center" id="sidenavToggler">
            <i class="fa fa-fw fa-angle-left"></i>
          </a>
        </li>
      </ul>
      <ul class="navbar-nav ml-auto">
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle mr-lg-2" id="messagesDropdown" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="fa fa-fw fa-globe"></i>
            <span class="d-lg-none">Messages
              <span class="badge badge-pill badge-primary">12 New</span>
            </span>
            <span class="indicator text-primary d-none d-lg-block">
              <i class="fa fa-fw fa-circle"></i>
            </span>
          </a>
          <div class="dropdown-menu" aria-labelledby="messagesDropdown">
           
            <a class="dropdown-item" href="#">
              <strong>Français</strong>
              
            </a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="#">
              <strong>English</strong>
             
            </a>
           
          </div>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle mr-lg-2" id="alertsDropdown" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="fa fa-fw fa-bell"></i>
            <span class="d-lg-none">Alerts
              <span class="badge badge-pill badge-warning">6 New</span>
            </span>
            <span class="indicator text-warning d-none d-lg-block">
              <i class="fa fa-fw fa-circle"></i>
            </span>
          </a>
          <div class="dropdown-menu" aria-labelledby="alertsDropdown">
            <h6 class="dropdown-header">New Alerts:</h6>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="#">
              <span class="text-success">
                <strong>
                  <i class="fa fa-long-arrow-up fa-fw"></i>Status Update</strong>
              </span>
              <span class="small float-right text-muted">11:21 AM</span>
              <div class="dropdown-message small">This is an automated server response message. All systems are online.</div>
            </a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="#">
              <span class="text-danger">
                <strong>
                  <i class="fa fa-long-arrow-down fa-fw"></i>Status Update</strong>
              </span>
              <span class="small float-right text-muted">11:21 AM</span>
              <div class="dropdown-message small">This is an automated server response message. All systems are online.</div>
            </a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="#">
              <span class="text-success">
                <strong>
                  <i class="fa fa-long-arrow-up fa-fw"></i>Status Update</strong>
              </span>
              <span class="small float-right text-muted">11:21 AM</span>
              <div class="dropdown-message small">This is an automated server response message. All systems are online.</div>
            </a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item small" href="#">View all alerts</a>
          </div>
        </li>
        <li class="nav-item">
          <form class="form-inline my-2 my-lg-0 mr-lg-2">
            <div class="input-group">
              <input class="form-control search-top" type="text" placeholder="Search for..." />
              <span class="input-group-btn">
                <button class="btn btn-primary" type="button">
                  <i class="fa fa-search"></i>
                </button>
              </span>
            </div>
          </form>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="modal" data-target="#exampleModal">
            <i class="fa fa-fw fa-sign-out"></i>Logout</a>
        </li>
      </ul>
    </div>
  </nav>

  <div class="content-wrapper">
        {mainView === 'rapports_rentree_list' && <RapportsRentreeList />}
        {mainView === 'dashboad' && <Home />}
        {mainView === 'addSchool' && <SchoolList />}
        {mainView === 'createMySchool' && <CreateMySchool />}
        {mainView === 'start_rapport_rentree' && <Start />}
        {mainView === 'look_some_school' && <LookSomeSchool />}
        {mainView === 'enseignement_base' && <Intro />}
        {mainView === 'enseignement_general' && <>ICI LENSEIGNEMENT GENERAL</>}
        {mainView === 'etape1_esb_enseignement' && <Enseignement /> }
        {mainView === 'trans1' && <Trans1 />}
        {mainView === 'infrastructure' && <Infrastrucure />}
        {mainView === 'type_sale' && <Trans2 /> }
        {mainView === 'administration' && <Administration /> }
        {mainView === 'deroulement_rentree' && <Deroulement_rentree />}
        {mainView === 'repartition_effectifs' && <Repartition_effectifs />}
        {mainView === 'synthese_effectifs' && <Synthese_effectifs />}
        {mainView === 'repartition_effectifs_trois_annees' && <Repartition_effectifs_trois_annees />}
        {mainView === 'myenseigmentbase' && <MyEnseigmentBase />}
        {mainView === 'detail_update_rapport' && <DetailUpdateRapport />}
        {mainView === 'update_infrastructure' && <InfrastructureUpdate />}
  </div>
   
    <footer class="sticky-footer">
      <div class="container">
        <div class="text-center">
          <small>© SEDUC APP by CONSOLIDIS</small>
        </div>
      </div>
    </footer>
   
    <a class="scroll-to-top rounded" onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })} href="javascript:void(0)">
      <i class="fa fa-angle-up"></i>
    </a>
   
    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel" style={{width:'100%'}}>Voulez-vous vraiment vous deconnecter?</h5>
            <button class="close" type="button" style={{width:'10%'}} data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">Cliquez sur "oui" si vous êtes pret a laisser votre session</div>
          <div class="modal-footer">
            <button class="btn btn-secondary" style={{width:'50%'}} type="button" data-dismiss="modal">NON</button>
            <Button class="btn btn-primary" type="primary" loading={loading} onClick={logouter} style={{width:'50%'}} href="javascript:void(0)">OUI</Button>
          </div>
        </div>
      </div>
    </div>
   
  
        </>
    );
}