import { useEffect, useState } from "react";
import { APP_URL } from "../../../../URL";
import { useRecoilState } from "recoil";
import { mainViewState, relationIdState } from "../../../../atoms/AppAtome";
import { useLocalStorage } from "../../../../LocalStorageProvider";
import { useCookies } from "react-cookie";
import axios from "axios";


export default function Repartition_effectifs_trois_annees(){

    const [mainView, setMainView] = useRecoilState(mainViewState);
    const [relationId, setRelationId] = useRecoilState(relationIdState);
  
    const {saveData,readData} = useLocalStorage();
    const datas = readData();
    const [loading, setLoading] = useState(false);
    const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);

    const date = new Date();
    const year = date.getFullYear();

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.seduc_token}`
        };
        
      const config = {
        headers: headers, 
        };

    const [repartitionEffectifsId, setRepartitionEffectifsId] = useState(0);

    useEffect(() => {

        axios.post(APP_URL+'esb/save-trois-annee',{
            'rentreer_esb' : datas.rentreer_esb,
        },config).then((response) => {
            setRepartitionEffectifsId(response.data.id)
        }).catch((error) => {
            console.log(error);
        })

    },[]);


    return (
        <>
<div class="container-fluid">

<div class="card mb-3">
    <div class="card-body">
        <h2> Repartition des effectifs des trois dernieres annees </h2>
    </div>
</div>

 <div class="card mb-3">
    <div class="card-body">

    </div>
  </div>
</div>
        </>
    );
}