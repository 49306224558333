import { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Modal, Spin, Upload, notification } from 'antd';
import { useRecoilState } from 'recoil';
import { CheckCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useCookies } from 'react-cookie';
import { APP_URL } from '../../../../URL';
import { categoryIdState, produitIdState } from '../../../../atoms/godoAtom';
import { mainViewState } from '../../../../atoms/AppAtome';


const getBase64 = (file) => 
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default function AddImgProduct(){

    const [isModalOpenCover, setIsModalOpenCover] = useState();
    const [modalDataCover, setModalDataCover] = useState();

    const [categories, setCategies] = useState([]);
    const token = localStorage.getItem('token');
    const [titreLivre, setTitreLivre] = useState('');
    const [loading, setLoading] = useState(true);
    const [selected, setSelected] = useState(false);

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState([]);
    const [categoryId, setCategoryId] = useRecoilState(categoryIdState);
    const [productId, setProductId] = useRecoilState(produitIdState);
    const [mainView, setMainView] = useRecoilState(mainViewState);

    const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);

      const headers = {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${cookies.seduc_token}`
          };
        
        const config = {
          headers: headers,
          };



    const showModal = () => {
        setIsModalOpenCover(true);
      };
      const handleOk = () => {
        setIsModalOpenCover(false);
        setFileList([]);
      };
      const handleCancelAp = () => {
        setIsModalOpenCover(false);
        setFileList([]);
      };

  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };


      const handleChange = ({ fileList: newFileList }) => { setFileList(newFileList); setSelected(true); }
      const uploadButton = (
        <button
          style={{
            border: 0,
            background: 'none',
          }}
          type="button"
        >
          <PlusOutlined />
          <div
            style={{
              marginTop: 8,
            }}
          >
            Upload
          </div>
        </button>
      );

      useEffect(() => {

        console.log('PRODUCT ID', productId)
        
      },[fileList,selected]);
    

    return (
        <>
     
      <center>
      <hr />
      <h3>Ajoutez une photo de previsualisation de cette categorie</h3>
      <Upload
            customRequest={(file) => {
              
              return axios.post(`${APP_URL}produit/${productId}/file`, {
                file,
              },config).then((response) => {
                console.log(response.data)
                setFileList(response.data);
                setModalDataCover(response.data);

                notification.open({
                    message:(
                       <div style={{ color: 'white' }}>
                         Enregistrer avec success
                       </div>
                      ),
                    description: '',
                    placement:'bottomLeft', 
                    icon: <CheckCircleOutlined />,
                    style: {
                        backgroundColor: 'green', // Couleur de fond personnalisée
                        color:'white',
                        // Autres styles personnalisés...
                      },
                  });

                  
                  setMainView('product-list');
               
               
              }).catch((error) => {
                console.log(error.response.data);
              });
            }}

            listType="picture-card"
            fileList={fileList}
            onPreview={handlePreview}
            onChange={handleChange}
            name="file"
          >
            {fileList.length >= 1 ? null : uploadButton}
          </Upload>
          </center>

          <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
            <img
              alt="example"
              style={{
                width: '100%',
              }}
              src={previewImage}
            />
          </Modal>
       
       
        </>
    );
}