import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useRecoilState } from "recoil";
import { mainViewState } from "../../../atoms/AppAtome";
import { Button, notification } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import { APP_URL } from "../../../URL";
import axios from "axios";
import { produitIdState } from "../../../atoms/godoAtom";

let fournisseur = [];
let categorie = [];

export default function AddProduct(){

    const [produitsData, setProduitsData] = useState({
        nom: '',
        prixUnitaire:'',
        marque:'',
        fournisseur:fournisseur,
        categorie:categorie,
        taille:'',
        poids:'',
        pays: '',
        adresse: '',
        description: ''
      });

      const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);

      const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${cookies.seduc_token}`
          };
        
        const config = {
          headers: headers,
          };

          const [loading, setLoading] = useState(false);
          const [mainView, setMainView] = useRecoilState(mainViewState);
          const [fournisseurs, setFournisseurs] = useState([]);
          const [categories, setCategies] = useState([]);

          const [productId, setProductId] = useRecoilState(produitIdState);
          
          useEffect(() => {
            
            axios.get(APP_URL+'categorie',config).then((response) => {
                setCategies(response.data.data);
            }).catch((error) => {
                console.log(error);
            })

            axios.get(APP_URL+'fournisseur',config).then((response) => {
                setFournisseurs(response.data.data);
            }).catch((error) => {
                console.log(error);
            })
        
          },[]);

          const handleChange = (e) => {
            const { name, value } = e.target;
            setProduitsData((prevData) => ({
              ...prevData,
              [name]: value,
            }));
        
          };
        
          const handleSubmit = (e) => {
            e.preventDefault();

            setLoading(true);

           
            axios.post(APP_URL+'save/produit',produitsData,config).then((response) => {
              setLoading(false);

            //   setCategoryId(response.data.data.category_id);
              console.log('F',response.data);
              
            //   notification
            notification.open({
                message:(
                   <div style={{ color: 'white' }}>
                     Enregistrer avec success
                   </div>
                  ),
                description: '',
                placement:'bottomLeft', 
                icon: <CheckCircleOutlined />,
                style: {
                    backgroundColor: 'green', // Couleur de fond personnalisée
                    color:'white',
                    // Autres styles personnalisés...
                  },
              });

              console.log('ReTuRn',response.data)
              setProductId(response.data.data.product_id);

              setMainView('add-image-product');


            }).catch((error) => {
                console.log(error);
                setLoading(false);
            });

          }
         

    return (
        <>
     <div class="container-fluid">
      <div class="box_general padding_bottom">
           <div class="header_box version_2">
               <h2><i class="fa fa-plus"></i>Ajoute un nouveau produit</h2>
           </div>

<form onSubmit={handleSubmit} style={{background:'white', padding:'2%'}}>
<div className="row">

<div className="col-md-6 mb-3">
<label htmlFor="titre" className="form-label">
  Nom du produit
</label>
<input
  type="text"
  className="form-control"
  id="nom"
  name="nom"
  value={produitsData.nom}
  onChange={handleChange}
  required
/>
</div>


<div className="col-md-6 mb-3">
<label htmlFor="titre" className="form-label">
  Prix Unitaire du produit
</label>
<input
  type="text"
  className="form-control"
  id="nom"
  name="prixUnitaire"
  value={produitsData.prixUnitaire}
  onChange={handleChange}
  required
/>
</div>

<div className="col-md-6 mb-3">
<label htmlFor="titre" className="form-label">
  Marque du produit
</label>
<input
  type="text"
  className="form-control"
  id="nom"
  name="marque"
  value={produitsData.marque}
  onChange={handleChange}
  required
/>
</div>

<div className="col-md-6 mb-3">
<label htmlFor="titre" className="form-label">
  Taille du produit
</label>
<input
  type="text"
  className="form-control"
  id="nom"
  name="taille"
  value={produitsData.taille}
  onChange={handleChange}
  required
/>
</div>

<div className="col-md-12 mb-3">
<label htmlFor="titre" className="form-label">
  Poids du produit
</label>
<input
  type="text"
  className="form-control"
  id="nom"
  name="poids"
  value={produitsData.poids}
  onChange={handleChange}
  required
/>
</div>

<div className="col-md-12 mb-3" >

<label htmlFor="titre" className="form-label">
   Fournisseur du produit
</label>

<div style={{background:'gray', color:'white', padding:'2%'}}>
{fournisseurs.map((item) => (
    <div>
      <input type="checkbox" className="form-control" onClick={() => { fournisseur.push(item.id)}} name={item.id} /> <span onClick={() => { fournisseur.push(item.id)}}>{item.nom}</span>
       <hr />
    </div>
))}
</div>

</div>

<div className="col-md-12 mb-3" >

<label htmlFor="titre" className="form-label">
   Categories du produit
</label>

<div style={{background:'gray', color:'white', padding:'2%'}}>
{categories.map((item) => (
    <div>
      <input type="checkbox" className="form-control" onClick={() => { categorie.push(item.id)}} name={item.id} /> <span onClick={() => { categorie.push(item.id)}}>{item.nom}</span>
       <hr />
    </div>
))}
</div>

</div>

<div className="col-md-12 mb-3" >

<label htmlFor="titre" className="form-label">
  Description du produit
</label>
<textarea
  className="form-control"
  id="description"
  name="description"
  value={produitsData.description}
  onChange={handleChange}
  required
></textarea>
</div>

</div>

<Button error className="btn btn-danger"  htmlType='submit' loading={loading}>Enregistre le nouveau cours</Button>

</form>
       </div>
     </div>
     <br /><br /><br />
        </>
    );
}