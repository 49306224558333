import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useRecoilState } from "recoil";
import { mainViewState } from "../../../atoms/AppAtome";
import { APP_URL } from "../../../URL";
import axios from "axios";
import { Button, notification } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import { categoryIdState } from "../../../atoms/godoAtom";


export default function AddCategorie(){

    const [categorieData, setCategorieData] = useState({
        nom: '',
        description: ''
      });

      const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);

      const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${cookies.seduc_token}`
          };
        
        const config = {
          headers: headers,
          };

          const [loading, setLoading] = useState(false);
          const [mainView, setMainView] = useRecoilState(mainViewState);
          const [categoryId, setCategoryId] = useRecoilState(categoryIdState);

          useEffect(() => {
            
        
          },[]);

          const handleChange = (e) => {
            const { name, value } = e.target;
            setCategorieData((prevData) => ({
              ...prevData,
              [name]: value,
            }));
        
          };
        
          const handleSubmit = (e) => {
            e.preventDefault();

            setLoading(true);

            axios.post(APP_URL+'save/categorie',categorieData,config).then((response) => {
              setLoading(false);

              setCategoryId(response.data.data.category_id);
              console.log('F',response.data);
              
            //   notification
            notification.open({
                message:(
                   <div style={{ color: 'white' }}>
                     Enregistrer avec success
                   </div>
                  ),
                description: '',
                placement:'bottomLeft', 
                icon: <CheckCircleOutlined />,
                style: {
                    backgroundColor: 'green', // Couleur de fond personnalisée
                    color:'white',
                    // Autres styles personnalisés...
                  },
              });

              setMainView('add-image-category');


            }).catch((error) => {
                console.log(error);
                setLoading(false);
            });

          }
         
    return (
        <>
    <div class="container-fluid">
      <div class="box_general padding_bottom">
           <div class="header_box version_2">
               <h2><i class="fa fa-plus"></i>Ajoute une nouvelle categorie</h2>
           </div>
        
           <form onSubmit={handleSubmit} style={{background:'white', padding:'2%'}}>
              <div className="row">

         <div className="col-md-12 mb-3">

            <label htmlFor="titre" className="form-label">
              Titre de la categorie
            </label>
            <input
              type="text"
              className="form-control"
              id="nom"
              name="nom"
              value={categorieData.nom}
              onChange={handleChange}
              required
            />
          </div>

<div className="col-md-12 mb-3">

<label htmlFor="titre" className="form-label">
  Titre de la categorie
</label>
<textarea
  className="form-control"
  id="description"
  name="description"
  value={categorieData.description}
  onChange={handleChange}
  required
></textarea>
</div>

<Button error className="btn btn-danger"  htmlType='submit' loading={loading}>Enregistre le nouveau cours</Button>
         

              </div>
            </form>

       </div>
     </div>
     <br /><br /><br />
        </>
    );
}