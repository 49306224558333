import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useRecoilState } from "recoil";
import { mainViewState } from "../../../atoms/AppAtome";
import { Spin } from "antd";
import axios from "axios";
import { APP_URL } from "../../../URL";


export default function FournisseurList(){

    const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);
    const [loading, setLoading] = useState(true);
    const [mainView, setMainView] = useRecoilState(mainViewState);
    const [category, setCategory] = useState([]);

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.seduc_token}`
        };
      
      const config = {
        headers: headers,
        };


        useEffect(() => {
            axios.get(APP_URL+'fournisseur',config).then((response) => {

                setLoading(false);
                // console.log('produitList',response.data);
                setCategory(response.data.data);

            }).catch((error) => {
                console.log(error);
            })
        },[]);


    return (
        <>
    <div class="container-fluid">
      <div class="box_general padding_bottom">
           <div class="header_box version_2">
               <h2><i class="fa fa-list"></i>Liste des fournisseurs</h2>
           </div>
        
        {loading?<>
        
            <center>
                <br /><br />
                <Spin  size="large" />
            </center>
        </>:<>

            {category.map((item) => (
                <>
                    <h5> <span className="fa fa-user"> </span> &nbsp; {item.nom} </h5>
                </>
            ))}
            
        </>}
          
       </div>
     </div>
     <br /><br /><br />
        </>
    );
}