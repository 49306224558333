import { atom } from "recoil";


export const categoryIdState = atom({
    key:'categoryIdState',
    default:null
});

export const produitIdState = atom({
    key:'produitIdState',
    default:null
});