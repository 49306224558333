// LoginPage.jsx

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { useCookies } from "react-cookie";

import './css/LoginPage.css'; // Assurez-vous d'avoir un fichier CSS séparé
import { langState } from '../atoms/AppAtome';
import axios from 'axios';
import { APP_URL } from '../URL';
import { Button, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/vlogo.png'

const LoginPage = () => {

  const { t } = useTranslation();
  const [lang, setLang] = useRecoilState(langState);

  const [formData, setFormData] = useState({
    username:'',
    password:'',
    typeRaport: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name] : e.target.value
    });
  };

 

  
  const [loading, setLoading] = useState(false);
  const [cookies,setCookie,removeCookie] = useCookies(['seduc_token']);
  const navigate = useNavigate();


  const handleSubmit = (e) => {

    e.preventDefault();

    if(formData.username != '' && formData.password != ''){
    setLoading(true);
    // Logique de connexion ici
    axios.post(APP_URL+'login_check',formData).then((response) => {

      console.log(response.data);
      setLoading(false);

      // on enregistre le token dans le cookies
      const expiresT = new Date();
         expiresT.setHours(expiresT.getHours() + 1);

         setCookie('seduc_token',response.data.token,{path:'/', expires: expiresT}); // on enregistre le Token
         return navigate('/lookup');

    }).catch((error) => {

      message.error(error.response.data.message);
      console.log(error);
      setLoading(false);

    });

   }else{
    message.warning('un ou plusieurs champs sont manquantes');
   }

  };

  const handleLanguageChange = (selectedLang) => {
    setLang(selectedLang);
  };

  return (
    <div className="login-container">
      <div className="language-selector">
        <br />
        {/* <label htmlFor="langSelect">{t('selectLanguage')}</label> */}
        <select id="langSelect" className='form-control' onChange={(e) => handleLanguageChange(e.target.value)} value={lang}>
          <option value="en">English</option>
          <option value="fr">Français</option>
        </select>
      </div>

      <div className="login-form">
        <center>
            <h3 style={{display:'inline'}}>
                <img src={`${logo}`} style={{width:'200px', height:'80px'}} />
            </h3>
        </center>
        
        <form onSubmit={handleSubmit}>
          <label htmlFor="username">{t('username')}</label>
          <input type="text" name='username' id="username" className='form-control' value={formData.username} onChange={handleChange} />
            <br />
          <label htmlFor="password">{t('password')}</label>
          <input type="password" name='password' className='form-control' id="password" value={formData.password} onChange={handleChange} />
             <a href='' style={{color:'#e84545'}}>Mot de passe oublier</a>
             <br /><br />
          <button type="submit" disabled={loading} style={{background:'#e84545'}} className='btn btn-primary'>{t('loginButton')}</button>
        </form>
      </div>
      <br />
      <center>
        &copy; GoDo by <a href='https://www.consolidis.com' target='_blank' style={{color:'#e84545'}}>Consolidis technology</a>
      </center>
      
    </div>
  );
};

export default LoginPage;
